* {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background-color: white;
    scroll-behavior: smooth;
    width: 100%;
}

a {
    color: unset;
    text-decoration: none;
}

.App {
    margin: 0;
}

.app_button {
    display: none;
}

.app_footer {
    z-index: 99;
}

.app_notification {
    position: fixed;
    margin-top: 0%;
    z-index: 99;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
}

@media screen and (max-width: 1600px) {
    .app_button {
        display: flex;
        position: fixed;
        z-index: 10;
        right: 5%;
        bottom: 20%;
        -webkit-user-select: auto;
        user-select: auto;
    }
}

@media screen and (max-width: 1052px) {
    .app_button {
        display: none;
    }
}

@media screen and (max-width: 430px) {
    .app_button {
        display: flex;
        position: fixed;
        z-index: 10;
        right: 15%;
        bottom: 25%;
        -webkit-user-select: auto;
        user-select: auto;
    }
}

@media screen and (max-width: 400px) {
    .app_notification {
        margin-top: -20%;
    }
}