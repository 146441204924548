/*@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500&family=DM+Serif+Display&family=Lato:wght@100;300;400;700;900&family=Overpass:wght@100;200;300;400&family=Poppins:wght@100;200;300;400;500&family=Raleway:wght@100;200;300;400;500&family=Roboto+Flex:opsz,wght@8..144,200;8..144,300;8..144,400&display=swap');
*/
@font-face {
  font-family: "DM Serif Display";
  src: url(./fonts/DMSerifDisplay-Regular.ttf);
}

@font-face {
  font-family: "Roboto Flex";
  src: url(./fonts/RobotoFlex-VariableFont_GRAD\,XTRA\,YOPQ\,YTAS\,YTDE\,YTFI\,YTLC\,YTUC\,opsz\,slnt\,wdth\,wght.ttf);
  font-weight: 200 400;
}

@font-face {
  font-family: "Raleway";
  src: url(./fonts/Raleway-VariableFont_wght.ttf);
  font-weight: 100 500;
}

@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins-Thin.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato-Thin.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato-Black.ttf);
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Barlow";
  src: url(./fonts/Barlow-Thin.ttf);
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Barlow";
  src: url(./fonts/Barlow-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Barlow";
  src: url(./fonts/Barlow-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Barlow";
  src: url(./fonts/Barlow-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Barlow";
  src: url(./fonts/Barlow-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Overpass";
  src: url(./fonts/Overpass-VariableFont_wght.ttf);
  font-weight: 100 400;
}

:root {
  --font-family: "Overpass", sans-serif;
  --font-family1: "Barlow", sans-serif;
  --font-family2: "Poppins", sans-serif;
  --font-family3: 'Raleway', sans-serif;
  --font-family4: 'Lato', sans-serif;
  --font-family5: 'DM Serif Display', sans-serif;
  --font-family6: 'Roboto Flex', sans-serif;
  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  --arrow-button: #056b70ff;
  --arrows: #b2a365de;
}