/* ---------------------------------------- [BEGIN] Home Block 1 ---------------------------------- */

.home_outter_container_block1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 270px auto 0px auto;
    width: 100%;
    gap: 20px;
}

.home_container_welcome {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
}

.home_container_welcome span {
    text-align: center;
    letter-spacing: 2px;
    font-family: var(--font-family6);
    color: #056b70ff;
    font-size: 48px;
    font-weight: 600;
}

.home_container_welcome h1 {
    letter-spacing: 2px;
    font-family: var(--font-family6);
    color: #b2a365de;
    font-size: 45px;
    font-weight: 500;
}

/* ---------------------------------------- [END] Home Block 1 ------------------------------------ */



/* ---------------------------------------- [BEGIN] Home Block 2 ---------------------------------- */

.home_outter_container_block2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto -20px auto;
    width: 80%;
    overflow: hidden;
    gap: 20px;
}

.home_logo_separator {
    width: 65px;
    position: relative;
    z-index: 2;
}

.home_logo_separator:before {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    background: #b2a365de;
    position: absolute;
    left: 130%;
    top: 50%;
}

.home_logo_separator:after {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    background: #b2a365de;
    position: absolute;
    right: 130%;
    top: 50%;
}

.home_logo_separator img {
    width: 65px;
    height: auto;
}

/* ---------------------------------------- [END] Home Block 2 ---------------------------------- */


/* ---------------------------------------- [BEGIN] Home Block 3 ---------------------------------- */

.home_outter_container_block3 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.home_foreground_img {
    position: relative;
    content: "";
    width: 80%;
    height: 700px;
    background: url(../../assets/home_front_dr_medefindt.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 300px;
}

.home_foreground_img_table {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 65%;
    height: 45%;
    background: linear-gradient(90deg, rgba(5, 107, 112, 0.8) 0%, rgba(5, 107, 112, 0.5) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: contain;
    bottom: -5%;
    left: -2%;
}

.home_foreground_img_table_left_content,
.home_foreground_img_table_right_content {
    font-weight: 300;
    font-family: var(--font-family1);
    color: white;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 35px;
    margin: 25px 30px 20px 50px;
}

.home_foreground_img_table_left_headline,
.home_foreground_img_table_right_headline {
    font-weight: 400;
    font-family: var(--font-family1);
    font-size: 35px;
    color: #b2a365de;
    margin: 20px 0px 0px 50px;
}

/* ---------------------------------------- [END] Home Block 3 ---------------------------------- */



/* ---------------------------------------- [BEGIN] Home Block 4 ---------------------------------- */

.home_outter_container_block4 {
    display: none;
    margin-bottom: 250px;
}

.home_inner_container_table_content p {
    font-weight: 400;
    font-family: var(--font-family1);
    font-size: 35px;
    color: #b2a365de;
    margin: 0px 0px 25px 50px;
}

.home_inner_container_table_content table {
    font-family: var(--font-family1);
    color: white;
    font-size: 18px;
    margin: 0px 0px 0px 50px;
}

.home___location,
.home___clock,
.inv_home___clock,
.inv_home___location {
    font-size: 35px;
    color: #b2a365de;
}

.inv_icons {
    font-size: 24px;
}

/* ---------------------------------------- [END] Home Block 4 ---------------------------------- */



/* ---------------------------------------- [BEGIN] Home Block 5 ---------------------------------- */

.home_outter_container_block5 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 250px;
    /*border-image: linear-gradient(90deg,rgb(255, 255, 255) 10%, rgba(178, 163, 101, 1) 20%, rgba(178, 163, 101, 1) 88%,rgb(255, 255, 255) 88%) 1; */
}

.home_inner_container_block5 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 50%;
    /*border-image: linear-gradient(90deg,rgb(255, 255, 255) 10%, rgba(178, 163, 101, 1) 20%, rgba(178, 163, 101, 1) 88%,rgb(255, 255, 255) 88%) 1; */
}

.home_inner_container_bg_img {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: 100%;
    height: 55vh;
    background: linear-gradient(70deg, rgba(255, 255, 255, 0.96) 25%, rgb(255, 255, 255, 0.78) 100%), url(../../assets/Praxislogo_neu.svg);
    background-position: center;
    background-attachment: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    /*
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    */
}

.home_inner_container_headline {
    font-family: var(--font-family6);
    font-size: 60px;
    font-weight: 600;
    color: #056b70ff;
    text-align: center;
}

.home_inner_container_block5_content p {
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    line-height: 2.5rem;
}

/* ---------------------------------------- [END] Home Block 5 ---------------------------------- */



/* ---------------------------------------- [BEGIN] Home Block 6/7/8 ---------------------------------- */

.home_outter_container_block6 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(170deg, rgba(5, 107, 112, 1) 0%, rgba(5, 107, 112, 1) 25%, rgb(5, 107, 112, 1) 100%);
    padding-top: 50px;
    border-bottom: 2px solid #b2a365de;
}

.home_inner_container_block6_headline {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 90%;
}

.home_outter_container_block6_content {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-color: #056b70ff;
}

.home_inner_container_block6_content {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 90%;
    gap: 50px;
    padding-top: 50px;
}

.home_inner_container_content_section1 {
    width: 40%;
}

.home_inner_container_content_section1 p {
    font-family: var(--font-family3);
    color: rgb(255, 255, 255);
    font-size: 22px;
    font-weight: 400;
    text-align: justify;
    -ms-text-align-last: center;
    text-align-last: auto;
    line-height: 2.5rem;
}

.home_outter_container_block7 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;
}

.home_inner_container_block7_headline_content {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin-bottom: 50px;
}

.home_outter_container_block7_content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 150px;
}

.home_inner_container_block7_content_bg_img {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 50px;
}

.home_inner_container_block7_content_content {
    position: relative;
    display: flex;
    width: 40%;
    padding-left: 20px;
}

.home_inner_container_block7_content_content p {
    font-family: var(--font-family3);
    color: #056b70ff;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    line-height: 2.5rem;
    text-align: justify;
    -ms-text-align-last: center;
    text-align-last: auto;
}

.home_outter_container_block8 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 250px;
    background: linear-gradient(40deg, rgba(5, 107, 112, 0.8) 0%, rgba(5, 107, 112, 1) 41%, rgb(5, 107, 112, 1) 100%);
    padding-bottom: 70px;
}



/* ---------------------------------------- [BEGIN] Headline ---------------------------------- */

.home_inner_container_block7_headline_content p,
.home_inner_container_block6_headline p {
    font-family: var(--font-family5);
    color: #b2a365de;
    font-size: 70px;
    font-weight: 600;
}

/* ---------------------------------------- [END] Headline ---------------------------------- */



/* ---------------------------------------- [BEGIN] Images ---------------------------------- */
.home_inner_container_block6_image {
    margin-bottom: 30px;
}

.home_inner_container_block6_image img {
    box-shadow: 10px 7px 10px 2px rgba(0, 0, 0, 0.2);
    width: auto;
    height: 550px;
}

.home_inner_container_block7_bg_img img {
    box-shadow: 10px 7px 10px 2px rgba(0, 0, 0, 0.2);
    width: 700px;
    height: auto;
}

/* ---------------------------------------- [END] Images ---------------------------------- */



/* ---------------------------------------- [BEGIN] Separator ---------------------------------- */
.home_separator2 {
    max-width: 100%;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 6vw solid rgba(5, 107, 112);
    margin-bottom: -1px;
}

.home_separator1 {
    max-width: 100%;
    height: 0;
    border-right: 100vw solid rgba(5, 107, 112);
    border-bottom: 6vw solid rgb(255, 255, 255);
    margin-top: -1px;
}

#separator {
    overflow: hidden;
}

/* ---------------------------------------- [END] Separator ---------------------------------- */



/* ---------------------------------------- [END] Home Block 6/7/8 ---------------------------------- */


/* ------------------------------------- Home praxis-team-leistungen --------------------------*/

.home_team_praxis_leistungen_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 350px;
    margin-bottom: 250px;
    margin-top: 250px;
}

.home_team_praxis_leistungen_container_bg {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    gap: 25px;
}

.home_box {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 25%;
    height: 250px;
    padding-bottom: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 20px;
}

.home_box:hover {
    -webkit-transform: scale(1.10);
    transform: scale(1.08);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.home_box_span {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.home_box_span {
    font-family: var(--font-family1);
    color: white;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.home_box_span:nth-child(1):hover {
    transition: all 0.5s;
    color: rgb(196, 216, 187);
    opacity: 1;
}

.home_box_span:nth-child(2):hover {
    transition: all 0.5s;
    color: #7fa0b4ff;
    opacity: 1;
}

.home_box_span:nth-child(3):hover {
    transition: all 0.5s;
    color: #aed9d3ff;
    opacity: 1;
}

.home_box a {
    font-family: var(--font-family1);
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.home_box:nth-child(1) {
    background: linear-gradient(170deg, rgb(5, 107, 112, 0.1) 0%, rgb(5, 107, 112, 0.5) 100%), url(../../assets/icon_praxis.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.home_box:nth-child(1) a:hover {
    color: #056b70ff;
    opacity: 1;
    transition: 0.2s;
}

.home_box:nth-child(2) {
    background: linear-gradient(210deg, rgba(174, 217, 211, 0.2) 0%, rgba(174, 217, 211, 0.5) 100%), url(../../assets/icon_team.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.home_box:nth-child(2) a:hover {
    color: #aed9d3ff;
    opacity: 1;
    transition: 0.2s;
}

.home_box:nth-child(3) {
    background: linear-gradient(170deg, rgb(178, 163, 101, 0.1) 0%, rgb(178, 163, 101, 0.55) 100%), url(../../assets/icon_leistungen.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.home_box:nth-child(3) a:hover {
    color: #b2a365de;
    opacity: 1;
    transition: 0.2s;
}


.genContainerFooter {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgb(5, 107, 112, 1);
}

.genContainerFooter_inner {
    position: relative;
    display: flex;
    justify-content: center;
    width: 80%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.genContainerFooter_inner p {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 100;
    color: #ffffff;
    text-align: center;

}

/* ---------------------------------------- [BEGIN] FONT-SIZES ------------------------------ */
@media screen and (max-width: 1500px) {

    .home_container_welcome span,
    .home_container_welcome h1 {
        font-size: 38px;
    }

    .home_inner_container_headline p {
        font-size: 45px
    }

    .home_inner_container_block5_content p {
        font-size: 25px;
    }

    .home_inner_container_block6_headline p,
    .home_inner_container_block7_headline_content p {
        font-size: 60px;
    }

}

@media screen and (max-width: 1000px) {

    .home_container_welcome span,
    .home_container_welcome h1 {
        font-size: 25px;
    }

    .home_inner_container_headline p {
        font-size: 30px
    }
    .home_inner_container_block5_content p {
        font-size: 20px;
    }

    .home_inner_container_block6_headline p,
    .home_inner_container_block7_headline_content p {
        font-size: 50px;
    }

    .home_inner_container_content_section1 p,
    .home_inner_container_block7_content_content p {
        font-size: 18px;
    }

    @media screen and (max-width: 700px) {
        .home_inner_container_headline p {
            font-size: 25px
        }

        .home_inner_container_block5_content p {
            font-size: 18px;
            line-height: 1.8rem;
        }
    }

    @media screen and (max-width: 600px) {

        .home_inner_container_block6_headline p,
        .home_inner_container_block7_headline_content p {
            font-size: 38px;
        }

        .home_inner_container_content_section1 p,
        .home_inner_container_block7_content_content p {
            line-height: 1.8rem;
        }
    }

    @media screen and (max-width: 400px) {

        .home_container_welcome span,
        .home_container_welcome h1 {
            font-size: 20px;
        }

        .home_inner_container_headline p {
            font-size: 20px
        }
        .home_inner_container_block5_content p {
            font-size: 16px;
        }

        .home_inner_container_block6_headline p,
        .home_inner_container_block7_headline_content p {
            font-size: 30px;
        }
        .home_inner_container_content_section1 p,
        .home_inner_container_block7_content_content p {
            font-size: 16px;
        }
    }
}

/* ---------------------------------------- [END] FONT-SIZES -------------------------------- */



@media screen and (max-width: 1871px) {
    .home_foreground_img_table {
        display: none;
    }

    .home_outter_container_block4 {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .home_inner_container_block4 {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 80%;
        background-color: rgba(5, 107, 112, .8);
        padding-top: 30px;
        padding-bottom: 30px;
        gap: 100px;
        line-height: 2rem;
    }

    .home_foreground_img {
        height: 605px;
        background-position-y: 55%;
        margin-bottom: 0;
    }

}

@media screen and (max-width: 1672px) {
    .home_foreground_img_table_left_content {
        font-size: 16px;
    }

    .home_foreground_img_table_left_headline {
        font-size: 28px;
    }

    .home_team_praxis_leistungen_container_bg {
        width: 100%;
    }

}

@media screen and (max-width: 1527px) {

    .home_inner_container_block6_image img {
        width: 600px;
        height: auto;
    }

    .home_inner_container_content_section1 {
        width: auto;
    }
}


@media screen and (max-width: 1506px) {

    .home_foreground_img_table_left_content {
        margin-left: 25px;
    }

    .home_foreground_img_table_left_headline {
        margin: 20px 0px 0px 22px;
    }

    .home_inner_container_block5 {
        width: 80%;
    }

}

@media screen and (max-width: 1400px) {

    .home_inner_container_block6_content {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .home_inner_container_block6_image {
        width: 80%;
    }

    .home_inner_container_block6_image img {
        width: 100%;
    }

    .home_inner_container_content_section1 {
        order: 2;
        width: 80%;
    }

    .home_inner_container_block6_headline {
        justify-content: flex-start;
        width: 72%;
    }

}

@media screen and (max-width: 1426px) {

    .home_container_welcome {
        width: 90%;
    }

    .home_outter_container_block2 {
        width: 100%;
    }

    .home_foreground_img {
        height: 550px;
        width: 100%;
    }

    .home_inner_container_block4 {
        width: 100%;
    }

    .home_inner_container_table_content p {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .home_inner_container_table_content table {
        font-size: 16px;
    }

    .home_inner_container_block7_content_content {
        width: 90%;
        padding-left: 0px;
    }

}

@media screen and (max-width: 1378px) {

    .home_inner_container_block7_headline_content {
        width: 72%;
        text-align: left;
    }

    .home_inner_container_block7_content_bg_img {
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
    }

    .home_inner_container_block7_bg_img {
        width: 90%;
    }

    .home_inner_container_block7_bg_img img {
        width: 100%;
    }

    .home_inner_container_block7_content_content {
        width: 90%;
    }
}

@media screen and (max-width: 1333px) {

    .home_team_praxis_leistungen_container {
        height: 250px;
    }

    .home_box p a {
        font-size: 15px;
    }

    .home_box {
        height: 220px;
        width: 29%;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 950px) {

    .home_box p a {
        font-size: 15px;
    }

    .home_box {
        height: 200px;
        padding-bottom: 30px;
    }

}

@media screen and (max-width: 880px) {
    .home_inner_container_block4 {
        gap: 20px;
    }
}

@media screen and (max-width: 756px) {

    .home_team_praxis_leistungen_container {
        height: 600px;
        margin-bottom: 100px;
        margin-top: 100px;
    }

    .home_team_praxis_leistungen_container_bg {
        flex-direction: column;
        width: 100%;
    }

    .home_box {
        width: 60%;
        padding-bottom: 10px;
    }

    .home_box a {
        font-weight: 200;
    }

    .home_preword_offer_2 {
        margin-bottom: 100px;
    }

    .home_outter_container_block5 {
        margin-bottom: 150px;

    }

    .home_inner_container_bg_img {
        background-attachment: scroll;
    }
}

@media screen and (max-width: 729px) {
    .home_foreground_img {
        height: 340px;
        width: 100%;
    }

    .home_outter_container_block4 {
        margin-bottom: 150px;
    }
}

@media screen and (max-width: 550px) {
    .home_inner_container_table_content p {
        margin: 20px 0px 0px 20px;
    }

    .home_inner_container_table_content table {
        margin: 20px 0px 0px 20px;
    }
}

@media screen and (max-width: 500px) {
    .home_foreground_img {
        height: 340px;
        width: 100%;
    }

    .home_inner_container_block6_image {
        width: 100%;
    }

    .home_inner_container_content_section1 {
        width: 100%;
    }

    .home_inner_container_block6_headline {
        width: 90%;
    }

    .home_inner_container_block7_headline_content {
        width: 90%;
    }

    .home_inner_container_block7_content_bg_img {
        width: 90%;
    }

    .home_inner_container_block7_bg_img,
    .home_inner_container_block7_content_content {
        width: auto;
    }

    .home_inner_container_bg_img {
        gap: 30px;
    }

    .genContainerFooter_inner p {
        font-size: 20px;

    }

}

@media screen and (max-width: 451px) {

    .home_box {
        padding-bottom: 20px;
    }

    .home_inner_container_block7_headline_content {
        margin-bottom: 20px;
    }

    .home_foreground_img {
        background-position-x: 60%;
    }

    .home_outter_container_block4 {
        bottom: -169%;
    }
}

@media screen and (max-width: 436px) {
    .home_foreground_img {
        height: 250px;
    }
}


@media screen and (max-width: 400px) {

    .home_preword_offer_content_img {
        height: 200px;
    }

    .home_inner_container_block6_image {
        height: 200px;
    }

    .home_outter_container_block7bg_img {
        height: 200px;
    }

}

@media screen and (max-width: 370px) {

    .inv_home___clock,
    .inv_home___location {
        font-size: 25px;
    }

    .home_inner_container_table_content p {
        font-size: 20px;
        margin: 0px 0px 10px 10px;
    }

    .home_inner_container_table_content table {
        font-size: 13.5px;
        margin: 0px 0px 10px 10px;
    }

    .home_inner_container_table_content table tbody tr td span {
        line-height: 2rem;
    }

    .home_outter_container_block5 {
        margin-bottom: 70px;
    }

    .home_team_praxis_leistungen_container {
        height: 400px;
    }

    .home_box {
        width: 70%;
        padding-bottom: 7px;
    }

    .home_box a {
        font-size: 15px;
        font-weight: 200;
    }
}

@media screen and (max-width: 306px) {
    .home_inner_container_table_content p {
        font-size: 18px;
        margin: 0px 0px 10px 10px;
    }

    .home_inner_container_table_content table {
        font-size: 12px;
        margin: 0px 0px 10px 10px;
    }
}