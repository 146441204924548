.praxis_headline {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 270px;
}

.praxis__headline {
    position: relative;
    display: flex;
    width: 80%;
    margin-bottom: 50px;
    border-bottom: solid 2px #b2a365de;
}

.praxis__headline h1{
    font-family: var(--font-family5);
    color: #b2a365de;
    font-size: 70px;
    font-weight: 600;
}

.praxis__intro{
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 100px;
}

.praxis___intro{
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 80%;
}

.praxis____intro{
    position: relative;
    display: flex;
    width: 50%;
}

.praxis___intro p{
    font-family: var(--font-family3);
    color: #056b70ff;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    line-height: 2.5rem;
}

/* ---------------------------------------------- BUTTON -------------------------------------------------------------*/
.praxis_btn {
    font-family: var(--font-family2);
    font-size: 20px;
    font-weight: 200;
    color: white;
    background: #b2a365de;
    width: 300px;
    padding: 15px 15px;
    border: none;
    outline: none;
    position: relative;
    cursor: pointer;
    border-radius: 500px;
    transition: all 0.4s;
    margin-top: 30px;
}

.praxis_btn:hover {
    transition: all 0.4s;
    transform: scale(1.05);
    color: rgb(5, 107, 112);
    background: rgba(174, 217, 211);
}

/* ---------------------------------------------- BUTTON -------------------------------------------------------------*/

@media screen and (max-width: 1500px) {
    .praxis__headline h1{
        font-size: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .praxis__headline h1{
        font-size: 50px;
    }

    .praxis____intro{
        width: 100%;
    }
}

@media screen and (max-width: 700px) {

    .praxis___intro p{
        font-size: 18px;
        line-height: 1.8rem;
    }
}

@media screen and (max-width: 600px) {
    .praxis__headline h1{
        font-size: 38px;
    }
}

@media screen and (max-width: 500px) {

    .praxis_btn {
        font-family: var(--font-family2);
        font-weight: 300;
        font-size: 14px;
        width: 200px;
        padding: 15px 5px;
    }

    .praxis__headline {
        width: 90%;
    }

    .praxis___intro{
        width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .praxis__headline h1{
        font-size: 30px;
    }

    .praxis___intro p{
        font-size: 16px;
    }
}