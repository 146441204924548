.jobOffers_container_headline {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 270px;
}

.jobOffers_headline {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 80%;
    margin-bottom: 50px;
}

.jobOffers_headline h1 {
    color: #b2a365de;
    font-family: var(--font-family5);
    font-size: 70px;
    font-weight: 600;
}

.jobOffers_headline h1 span {
    background: #b2a365de;
    color: white;
    padding: 0px 12px 0px 12px;
}

.skills__container_AN_AG_content span {
    font-size: 25px;
    background: #b2a365de;
    color: white;
    padding: 0px 5px 0px 5px;
}

.jobOffers_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.jobOffers__container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-bottom: 50px;
}

.jobOffers__picture {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.jobOffers__picture p {
    font-family: var(--font-family1);
    color: #056b70ff;
    font-size: 27px;
    font-weight: 300;
    line-height: 2.8rem;
}

.skills__container_AN_AG ul li {
    list-style-position: inside;
}

.skills_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    width: 100%;
}

.skills__container_AN_AG {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 100px;
    width: 80%;
}

.skills__container_AN_AG ul {
    font-family: var(--font-family1);
    color: #056b70ff;
    font-size: 22px;
    font-weight: 300;
    line-height: 2.8rem;
}

.apply_mail {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
}

.apply_email_icon {
    font-size: 25px;
    color: #b2a365de;
}

.apply_email_icon:hover {
    transition: all 0.5s;
    color: #056b70ff;
}

.apply_mail a {
    font-family: var(--font-family2);
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: rgb(5, 107, 112, 1);
}





@media screen and (max-width: 1440px) {
    .skills__container_AN_AG {
        gap: 50px;
    }
}

@media screen and (max-width: 700px) {

    .jobOffers__picture p {
        font-size: 20px;
        line-height: 1.8rem;
    }

    .skills__container_AN_AG ul {

        font-size: 16px;
        line-height: 1.5rem;
    }

    .skills__container_AN_AG_content span {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {

    .jobOffers_headline {
        width: 90%;
    }

    .jobOffers__container {
        width: 90%;
    }

    .skills__container_AN_AG {
        width: 90%;
    }

}

@media screen and (max-width: 400px) {

    .jobOffers__picture p {
        font-size: 14px;
        line-height: 1.5rem;
    }

    .skills__container_AN_AG ul {

        font-size: 14px;
        line-height: 1.5rem;
    }

    .apply_mail a {
        font-size: 15px;
    }
}

@media screen and (max-width: 1500px) {
    .jobOffers_headline h1 {
        font-size: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .jobOffers_headline h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .jobOffers_headline h1 {
        font-size: 38px;
    }
}

@media screen and (max-width: 400px) {
    .jobOffers_headline h1 {
        font-size: 30px;
    }
}