.kontakt_headline {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 270px;
    margin-bottom: 50px;
}

.kontakt__headline {
    position: relative;
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px solid #b2a365de;
    width: 80%;
}

.kontakt__headline h1 {
    color: #b2a365de;
    font-family: var(--font-family5);
    font-size: 70px;
    font-weight: 600;
}

.kontakt {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.content_gmaps_adress_container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 80%;
    height: 600px;
    gap: 100px;
    margin-bottom: 150px;
}

.content_gmaps {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
    background: linear-gradient(90deg, rgb(230, 230, 230, 0.5) 0%, rgb(230, 230, 230, 0.5) 100%), url(../../assets/gmaps.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.content_adress {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.5) 100%), url(../../assets/Praxislogo_Kontakt_bg.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.content_adress p {
    font-family: var(--font-family4);
    color: #056b70ff;
    font-size: 30px;
    font-weight: 300;
    line-height: 2.8rem;
    padding-top: 15px;
}

.content__adress {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.cookieBanner_gmaps {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 65%;
}

.gmaps_button {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 80%;
    background-color: rgb(5, 107, 112);
    font-family: var(--font-family2);
    font-size: 17px;
    font-weight: 300;
    color: white;
    padding: 12px;
    border: none;
    cursor: pointer;
    border-radius: 500px;
}

.gmaps_button:hover {
    transition: 0.5s;
    color: #b2a365de;
}

.cookieBanner_gmaps p {
    font-family: var(--font-family2);
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.content__adress p span {
    line-height: 250%;
}

.content_sep {
    content: "";
    width: 100%;
}

.content__sep {
    border-color: rgb(5, 107, 112, 0.8);
}

.kontakt_phone_icon,
.kontakt_email_icon{
    font-size: 35px;
    color: #b2a365de;
}

.kontakt_parking {
    font-size: 44px;
    color: #b2a365de;
}

/* ---------------------------------------------- BUTTON -------------------------------------------------------------*/
.kontakt_btn {
    font-family: var(--font-family2);
    font-size: 20px;
    font-weight: 200;
    color: white;
    background: rgb(5, 107, 112);
    border: 1px solid rgb(5, 107, 112, 0.5);
    width: 300px;
    padding: 15px 15px;
    border: none;
    outline: none;
    position: relative;
    cursor: pointer;
    border-radius: 500px;
    transition: all 0.4s;
    margin-top: 30px;
}

.kontakt_btn:hover {
    transition: all 0.4s;
    transform: scale(1.05);
    color: #b2a365de;
    border: 1px solid rgb(5, 107, 112, 0.5);
    background: rgb(255, 255, 255);
}

/* ---------------------------------------------- BUTTON -------------------------------------------------------------*/


/* ---------------------------------------- Font Size Devices -----------------------------------------*/

@media screen and (max-width: 1500px) {
    .kontakt__headline h1{
        font-size: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .kontakt__headline h1{
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .kontakt__headline h1{
        font-size: 38px;
    }
}

@media screen and (max-width: 400px) {
    .kontakt__headline h1{
        font-size: 30px;
    }
}

/* ---------------------------------------- Font Size Devices -----------------------------------------*/

@media screen and (max-width: 2000px) {
    .content_adress p {
        font-size: 20px;
    }
}

@media screen and (max-width: 1225px) {
    .content_adress p {
        font-size: 18px;
    }

}

@media screen and (max-width: 1124px) {

    .content_gmaps_adress_container {
        flex-direction: column;
        align-items: flex-start;
        height: 1000px;
        width: 90%;
    }

    .content_adress {
        width: 100%;
    }

    .content_gmaps {
        width: 100%;
        height: 100%;
    }

    .kontakt__headline {
        justify-content: center;
        text-align: left;
    }

    .content_adress p {
        line-height: 1.8rem;
    }

    .content_adress p span {
        line-height: 3rem;
    }

}

@media screen and (max-width: 565px) {
    .cookieBanner_gmaps p {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {

    .kontakt__headline {
        width: 90%;
    }

    .content_adress p {
        font-size: 24px;
    }

    .kontakt_phone_icon,
    .kontakt_email_icon {
        font-size: 25px;
        color: #b2a365de;
    }

    .kontakt_parking {
        font-size: 28px;
    }

    .kontakt__banner p {
        font-size: 20px;
    }

    .content_adress p {
        font-size: 15px;
    }

    .kontakt_btn {
        font-family: var(--font-family2);
        font-weight: 300;
        font-size: 14px;
        width: 200px;
        padding: 18px 7px;
    }

    .gmaps_button {
        font-size: 14px;
        width: 150px;
        padding: 10px 7px;
        top: 85%;
    }

    .content_gmaps_adress_container {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 315px) {
    .cookieBanner_gmaps p {
        font-size: 12px;
    }
}