.background_footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    background-color: rgba(174, 217, 211);
    /*background: url(../../assets/Footer_background.svg);*/
}

.subcontainer_background_footer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px #056b70ff solid;
    /*border-image: linear-gradient(90deg, #056b70ff 20%,#f4e6a8 45%, #f4e6a8 50%,#f4e6a8 55%, #056b70ff 100% ) 1; */
    /*border-image: linear-gradient(90deg, #056b70ff 30%,#b2a365de 45%, #b2a365de 50%, #b2a365de 55%, #056b70ff 100% ) 1;*/
    width: 90%;
    gap: 35px;
    margin-bottom: 30px;
}

.border_logo {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /*border-image: linear-gradient(90deg, #056b70ff 20%,#f4e6a8 45%, #f4e6a8 50%,#f4e6a8 55%, #056b70ff 100% ) 1; */
    width: 100%;
    gap: 80px;
}

.infobox_footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0px 50px 0px 0px;
    gap: 10px;
    line-height: 1.4rem;
}

.infobox_footer table {
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 13.5px;
}

.infobox_footer h1 {
    font-family: var(--font-family1);
    font-weight: 500;
    color: #056b70ff;
    font-size: 17.55px;
    border-bottom: 1px solid #056b70ff;
    margin-bottom: 7px;
}

.infobox_footer h2,
.infobox_footer h3,
.infobox_footer p {
    font-family: var(--font-family2);
    font-size: 13.5px;
    color: #056b70ff;
    font-weight: 400;
    line-height: 0.8rem;
}

.oeffnungszeiten_headline {
    font-family: var(--font-family1);
    font-weight: 500;
    color: #056b70ff;
    font-size: 17.55px;
    border-bottom: 1px solid #056b70ff;
}

.Logo_footer {
    height: 65px;
    opacity: 0.85;
}

.container_icon_mail {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
}

.container_icon_mail p a {
    font-family: var(--font-family1);
    font-weight: 400;
    color: #056b70ff;
    font-size: 17.55px;
    max-width: 150px;
}

.container_icon_phone {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    z-index: 2;
}

.container_icon_phone p a {
    font-family: var(--font-family1);
    color: #056b70ff;
    font-weight: 400;
    font-size: 17.55px;
    max-width: 150px;
}

.invisible_container_datenschutzerklärung_impressum {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0px 50px 70px 0px;
    gap: 30px;
    z-index: 2;
}

.invisible_container_datenschutzerklärung_impressum p {
    font-family: var(--font-family2);
    font-size: 13.5px;
    color: #056b70ff;
}

.footer_instagram {
    position: absolute;
    display: flex;
    justify-content: space-between;
    right: 30%;
    bottom: 0%;
    z-index: 3;
}



.footer_instagram a {
    font-family: var(--font-family1);
    font-weight: 400;
    color: #056b70ff;
    font-size: 17.55px;
    cursor: pointer;
    z-index: 3;
}

.insta_icon {
    font-size: 35px;
}

.container_datenschutz_impressum {
    position: absolute;
    display: flex;
    justify-content: space-between;
    right: 0%;
    bottom: 0;
    font-size: 12px;
    z-index: 2;
}

.container_datenschutz,
.container_datenschutz p {
    font-family: var(--font-family2);
    display: flex;
    color: #056b70ff;
    font-size: 12px;
}

.container_impressum,
.container_impressum p {
    position: absolute;
    display: flex;
    margin-right: 100px;
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 12px;
    margin-left: -40px;
}

.invisible_container_datenschutzerklärung_impressum {
    display: none;
}

.copyright_container {
    background-color: rgba(174, 217, 211);
    width: 100%;

}

.copyright_container p {
    background-color: rgba(174, 217, 211);
    color: #056b70ff;
    width: 100%;
    font-family: var(--font-family2);
    font-size: 8px;
    text-align: center;
    margin-top: -15px;
}


/* ICONS */

.insta_icon {
    color: #056b70ff;
    vertical-align: -0.125em;
}

.insta_icon:hover {
    color: #b2a365de;
    transition: all 0.5s;
}

.email_icon {
    color: #056b70ff;
}

.phone_icon {
    color: #056b70ff;
    vertical-align: -0.125em;
}

.clock_icon {
    color: #056b70ff;
}

.Logo_footer:hover {
    transition: all 0.6s;
    cursor: pointer;
    opacity: 1;
}

.container_datenschutz p a:hover {
    color: #b2a365de;
    transition: 0.4s;
}

.container_impressum p a:hover {
    color: #b2a365de;
    transition: 0.4s;
}

.container_icon_mail p a:hover {
    color: #b2a365de;
    transition: 0.4s;
}

.container_icon_phone p a:hover {
    color: #b2a365de;
    transition: 0.4s;
}

.scrolltotop {
    background-color: #b2a365de !important;
}

.footer_img_logo_container {
    display: flex;
    position: absolute;
    bottom: 10%;
    right: -0.5%;
    margin: auto;
    height: 100px;
    width: 100px;
    background: url(../../assets/QRCode.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer_img_logo_container2 {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
}

/*--------------------------------------- INSTA FOOTER ANIMATION ----------------------------*/

.wrapper {
    display: inline-flex;
    list-style: none;
}

.wrapper .icon {
    position: relative;
    background: #056b70ff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 20px;
    height: 20px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
    position: absolute;
    top: 0;
    font-family: var(--font-family1);
    font-weight: 400;
    font-size: 16px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
}

.insta_icon_desktop {
    font-size: 30px;
    color: white;
    vertical-align: middle;
}

/*--------------------------------------- INSTA FOOTER ANIMATION ----------------------------*/
@media screen and (max-width: 1680px) {
    .container_icon_phone {
        width: 90%;
        margin-left: 0px;
    }
}


@media screen and (max-width: 1206px) {
    .footer_instagram {
        position: relative;
        left: 0%;
        width: 50%;
    }
}

@media screen and (max-width: 712px) {
    .footer_img_logo_container {
        bottom: 80%;
        right: -0.5%;
    }
}


@media screen and (max-width: 685px) {

    .infobox_footer table {
        font-size: 13.5px;
    }

    .infobox_footer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0px 50px 0px 0px;
        gap: 10px;
    }

    .border_logo {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        /*border-image: linear-gradient(90deg, #056b70ff 20%,#f4e6a8 45%, #f4e6a8 50%,#f4e6a8 55%, #056b70ff 100% ) 1; */
        width: 100%;
        margin-bottom: 50px;
    }

    .container_icon_mail {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .container_icon_phone {
        margin-bottom: 20px;
    }

    .subcontainer_background_footer {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        /*border-image: linear-gradient(90deg, #056b70ff 20%,#b2a365de 40%, #b2a365de 50%, #b2a365de 60%, #056b70ff 100% ) 1; */
        gap: 0px;
        margin-bottom: 30px;
        padding-bottom: 65px;
    }

    .footer_img_logo_container {
        bottom: 83%;
    }
}

@media screen and (max-width: 550px) {

    .container_icons p a {
        font-size: 15px;
    }

    .Logo_footer {
        height: 55px;
    }

    .container_datenschutz_impressum {
        display: none;
    }

    .invisible_container_datenschutzerklärung_impressum {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }

    .container_icon_phone p a {
        font-size: 16px;
        max-width: 150px;
    }

    .container_icon_mail p a {
        font-size: 16px;
        max-width: 150px;
    }

    .subcontainer_background_footer {
        flex-direction: column;
        padding-bottom: 0px;
    }

    .footer_img_logo_container {
        bottom: 85%;
        right: -0.5%;
    }
}

@media screen and (max-width: 450px) {
    .footer_img_logo_container {
        display: none;
    }

    .wrapper {
        animation: none;
        transition: none;
    }

    .wrapper .icon {
        animation: none;
        transition: none;
    }

    .wrapper .tooltip {
        animation: none;
        transition: none;
    }

    .wrapper .tooltip::before {
        animation: none;
        transition: none;
    }
}

@media screen and (max-width: 430px) {
    .scrolltotop {
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .subcontainer_background_footer {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 350px) {

    .subcontainer_background_footer {
        margin-bottom: 20px;
    }

    .Logo_footer {
        height: 50px;
    }

    .invisible_container_datenschutzerklärung_impressum {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .invisible_container_datenschutzerklärung_impressum p {
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
    }

    .infobox_footer h2,
    .infobox_footer h3,
    .infobox_footer p {
        font-family: var(--font-family2);
        font-size: 12px;
    }

    .container_icon_phone {
        margin-left: -5px;

    }

    .container_icon_mail {
        margin-left: -5px;

    }

    .container_icon_phone p a {
        font-size: 14px;
        max-width: 150px;

    }

    .container_icon_mail p a {
        font-size: 14px;
        max-width: 150px;

    }

    .infobox_footer table {
        font-size: 12px;
    }

    .phone_icon {
        height: 20px;
    }

    .email_icon {
        height: 23px;
    }



}