.leistungen_headline {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 270px;
    margin-bottom: 150px;
}

.leistungen__headline {
    position: relative;
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px solid #b2a365de;
    width: 80%;
}

.leistungen__headline h1 {
    color: #b2a365de;
    font-family: var(--font-family5);
    font-size: 70px;
    font-weight: 600;
}

.leistungen_separator_opacity {
    max-width: 100%;
    height: 0;
    border-right: 99vw solid rgba(5, 107, 112);
    border-bottom: 6vw solid rgb(255, 255, 255);
    margin-top: -1px;
    overflow: hidden;
}

.leistungen_q_container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 250px;
    background: rgb(5, 107, 112, 1);
}

.leistungen__q_container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 80%;
    padding-top: 40px;
    padding-bottom: 40px;
}

.leistungen__q_container p {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 100;
    color: #ffffff;
    text-align: center;

}

#separator {
    overflow: hidden;
}

.spacer{
    margin-bottom: 200px;
}


/* ---------------------------------------- [BEGIN] Font-Size ---------------------------------- */

@media screen and (max-width: 1500px) {
    .leistungen__headline h1{
        font-size: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .leistungen__headline h1{
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .leistungen__headline h1{
        font-size: 38px;
    }
}

@media screen and (max-width: 400px) {
    .leistungen__headline h1{
        font-size: 30px;
    }
}
/* ---------------------------------------- [END] Font.-size ------------------------------------ */



/* ---------------------------------------- Font Size Devices -----------------------------------------*/


@media screen and (max-width: 610px) {

}

@media screen and (max-width: 500px) {

    .leistungen__q_container p{
        font-size: 20px;
    }
}

@media screen and (max-width: 408px) {
    .jugendliche_bg_img {
        height: 280px;
    }
}

@media screen and (max-width: 466px) {
    .behandlung_kids_img {
        width: 100%;
        height: 240px;
    }
}

@media screen and (max-width: 466px) {

    .leistungen_beratung_content_img1 {
        height: 250px;
    }

    .diagnostik_bg_img {
        height: 250px;
    }
}