/* -------------------------------------------------- NavigationBar STYLE --------------------------------------*/
.navbar {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    background: linear-gradient(240deg, rgba(5, 107, 112, 1) 40%, rgb(1, 134, 141, 0.9) 100%);
    /*background: linear-gradient(240deg,rgba(2, 75, 76, 1)  40%,rgb(1, 134, 141, 0.9) 100%); */
    height: 140px;
    width: 100%;
    box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.6s;
    top: 0;
    z-index: 99;
}

/* -------------------------------------------------- NavigationBar STYLE --------------------------------------*/



/* -------------------------------------------------- NavigationBar links STYLE --------------------------------------*/
.navbar__links {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.navbar__links_container {
    display: flex;
}

/* -------------------------------------------------- NavigationBar links STYLE --------------------------------------*/



/* -------------------------------------------------- NavigationBar logo STYLE --------------------------------------*/
.navbar__logo {
    margin-left: 120px;
}
/* -------------------------------------------------- NavigationBar logo STYLE --------------------------------------*/



/* -------------------------------------------------- Hovereffect Logo --------------------------------------*/
.pngimage {
    transition: all 0.6s;
}

.pngimage:hover {
    -webkit-filter: drop-shadow(0px 0px 2px rgb(236, 189, 20));
    filter: drop-shadow(0px 0px 2px rgb(236, 189, 20));
}

/* -------------------------------------------------- Hovereffect Logo --------------------------------------*/



/* -------------------------------------------------- TextStyle in NavBar --------------------------------------*/
.navbar__links_container p,
.navbar__menu p,
.hamburger_menu p {
    color: #ffffff;
    font-family: var(--font-family3);
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

/* -------------------------------------------------- TextStyle in NavBar --------------------------------------*/



/* -------------------------------------------------- HoverEffect of NavBar --------------------------------------*/
.navbar__links_container p a,
.navbar__links_container p a::before,
.navbar__links_container p a::after {
    transition: all .357s;
}

.navbar__links_container p a {
    position: relative;
}

.navbar__links_container p a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '';
    color: transparent;
    background: #b2a365de;
    height: 1px;
}

.navbar__links_container p a:hover::after {
    width: 100%;
}

.navbar__links_container p a:hover {
    color: #b2a365de;
}

/* -------------------------------------------------- HoverEffect of NavBar --------------------------------------*/


/* -------------------------------------------------- Hold color when clicked on navbar links --------------------------------------*/
.navbar__links_container a.active {
    color: #b2a365de;
}

/* -------------------------------------------------- Hold color when clicked on navbar links --------------------------------------*/



/* -------------------------------------------------- HAMBURGER STYLE --------------------------------------*/
.hamburger_container {
    display: none;
}

.hamburger_menu {
    display: none;
}

/* -------------------------------------------------- HAMBURGER STYLE --------------------------------------*/



/* -------------------------------------------------- CSS ANIMATE BurgerMenu ----------------------------------------*/
.animate__animated.animate__fadeInDown {
    animation-duration: 0.6s;
    background-color: #056b70ff;
}

/* -------------------------------------------------- CSS ANIMATE BurgerMenu ----------------------------------------*/


/*------------------------------- BUTTON WAVE Animation NAVBAR -------------------------------*/
.onlineBookingNavbar {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 250px;
    height: 60px;
    text-align: center;
    border-radius: 100px;
    overflow: hidden;
    right: 10%;
    border: 1px solid #aca56fff;
}

.onlineTxtDiv:hover{
    cursor: pointer;
}

.onlineTxtDiv {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:hover {
        .wave {
            top: -178px;
        }

        span {
            letter-spacing: 2.5px;
            transition: all 0.4s;
        }

        .onlineTermin_button {
            transition: all 0.4s;
        }
    }

    span {
        position: relative;
        z-index: 1;
        color: rgb(255, 255, 255);
        font-family: var(--font-family6);
        font-weight: 100;
        font-size: 17px;
        word-spacing: 3px;
        letter-spacing: 0.6px;
        transition: all 0.4s;
    }

    .wave {
        width: 300px;
        height: 250px;
        background-color: #aca56fff;
        box-shadow: inset 0 0 100px rgba(5, 107, 112, 0.5);
        position: absolute;
        left: 10;
        top: -90px;
        transition: 0.4s;

        &::before,
        &::after {
            width: 200%;
            height: 200%;
            content: '';
            position: absolute;
            top: 0;
            left: 20%;
            transform: translate(-50%, -75%);
        }

        &::before {
            border-radius: 85%;
            background-color: rgba(5, 107, 112);
            animation: wave 6s linear infinite;
        }

        &::after {
            border-radius: 85%;
            background-color: rgba(20, 20, 20, 0.2);
            animation: wave 10s linear infinite;
        }
    }
}

@keyframes wave {
    0% {
        transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}

/*------------------------------- BUTTON WAVE Animation NAVBAR -------------------------------*/



/* -------------------------------------------------- MEDIA DEVICES ----------------------------------------*/
/* @media wird verwendet, wenn max width nicht überschritten wird */
@media screen and (max-width: 2120px) {
    .onlineBookingNavbar {
        right: 1.5%;
    }
}

@media screen and (max-width: 1756px) {
    .onlineBookingNavbar {
        display: none;
    }
}


@media screen and (max-width: 1440px) {
    .navbar {
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

}

@media screen and (max-width: 1200px) {

    .navbar__links {
        display: none;
    }

    .onlineBookingNavbar {
        display: flex;
        position: relative;
        margin-top: 50px;
        width: 250px;
        height: 50px;
        text-align: center;
        border-radius: 500px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .onlineTxtDiv {
        justify-content: center;
    }

    .onlineTxtDiv span {
        padding-bottom: 0px;
    }

    .navbar {
        align-items: center;
        padding-top: 25px;
        padding-bottom: 25px;
        height: 50px;
        top: 0;
    }


    .navbar__logo {
        position: absolute;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .navbar__logo a img {
        height: 80px;
    }

    .hamburger_container {
        position: absolute;
        right: 5%;
        display: flex;
    }

    .hamburger_button {
        display: flex;
    }

    /* -------------------------------------------------- opacity for BurgerMenu ----------------------------------------*/

    /* -------------------------------------------------- opacity for BurgerMenu ----------------------------------------*/

    .hamburger_menu {
        display: flex;
        position: fixed;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 20px;
        align-items: center;
        padding-top: 40px;
        background: linear-gradient(240deg, rgba(5, 107, 112, 0.97) 0%, rgb(1, 134, 141, 0.97) 100%);
        border-top: 1.5px solid #b2a365de;
        z-index: 10;
        transition: 0.6s;
        /*margin-top: -50px;*/
        --animate-duration: 0.35s;
    }

}

@media screen and (max-width: 320px) {
    .hamburger_button {
        display: flex;
    }
}