.team_headline_container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 270px;
}

.team_headline {
    position: relative;
    display: flex;
    width: 80%;
    margin-bottom: 50px;
    border-bottom: solid 2px #b2a365de;
}

.team_headline h1 {
    font-family: var(--font-family5);
    color: #b2a365de;
    font-size: 70px;
    font-weight: 600;
}

.team_container_doc {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 250px;
}

.team__container_doc {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 12rem;
    width: 80%;
}

.team__container_doc_img {
    position: relative;
    display: flex;
    width: 500px;
    height: 600px;
    box-shadow: 0px 7px 10px 2px rgba(0, 0, 0, 0.2);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 40%, rgb(127, 160, 180, 0) 100%), url(../../assets/KFO_Medefindt.JPG);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
}

.team__container_ZFA_img {
    position: relative;
    display: flex;
    width: 500px;
    height: 600px;
    box-shadow: 0px 7px 10px 2px rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* --------------------------   FOTOS   --------------------------------*/

.team__container_ZFA_img:nth-child(2) {
    background: url(../../assets/ZFA_Kim.JPG);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.team__container_ZFA_img:nth-child(3) {
    background: url(../../assets/ZFA_Ina.JPG);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.team__container_ZFA_img:nth-child(4) {
    background: url(../../assets/ZFA_Carmen.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.team__container_ZFA_img:nth-child(5) {
    background: url(../../assets/ZFA_Sabrina.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.team__container_ZFA_img:nth-child(6) {
    background: linear-gradient(230deg, rgb(1, 134, 141, 0.8) 0%, rgb(1, 134, 141, 0.9) 100%), url(../../assets/Praxislogo_Lebenslauf.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* --------------------------   FOTOS   --------------------------------*/

.doc_curric {
    position: relative;
    display: flex;
    width: 600px;
    height: 850px;
    box-shadow: 0px 7px 10px 2px rgba(0, 0, 0, 0.2);
    background: linear-gradient(230deg, rgb(1, 134, 141, 0.8) 0%, rgb(1, 134, 141, 0.9) 100%), url(../../assets/Praxislogo_Lebenslauf.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
}

.doc_curric__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 100px auto;
    color: white;
    width: 100%;
}

.doc_curric__headline {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 20px auto 20px auto;
    color: white;
    width: 100%;
}

.doc_curric__headline h2 {
    font-family: var(--font-family6);
    font-size: 30px;
    font-weight: 300;
}

.doc_curric__content {
    position: relative;
    display: flex;
    width: 95%;
    text-align: left;
}

.doc_curric__content table tbody tr td {
    font-family: var(--font-family2);
    font-size: 20px;
    font-weight: 200;
    vertical-align: top;
    padding-bottom: 10px;
}

.doc_curric__content table tbody tr td span {
    color: #b2a365;
    font-weight: 400;
    border-left: 5px solid #b2a365;
    padding-left: 5px;
}

.doc_name_container {
    position: absolute;
    left: -3%;
    bottom: -3%;
    width: 100%;
    padding: 20px 0px 20px 0px;
    text-align: center;
    background: linear-gradient(240deg, rgba(5, 107, 112, 1) 30%, rgb(1, 134, 141, 0.5) 100%);
}

.doc_name_container h2 {
    font-family: var(--font-family6);
    font-size: 18px;
    font-weight: 400;
    color: #e6d688;
    letter-spacing: 1px;
}

.doc__name p {
    font-family: var(--font-family6);
    font-size: 35px;
    font-weight: 300;
    color: white;
}

.animate__animated.animate__backInRight {
    animation-duration: 0.75s;
}

.animate__animated.animate__backOutRight {
    animation-duration: 0.75s;
}


/*--------------------------------------------------- HOVER EFFECT BTN ------------------------------------------------------*/

.button_container {
    position: absolute;
    left: 30%;
    top: 107%;
    color: white;
}

.button_container_close {
    position: absolute;
    right: 0%;
    top: 0%;
}

.btn {
    background-color: rgb(5, 107, 112);
    border-radius: 0px;
    font-family: var(--font-family6);
    font-size: 20px;
    font-weight: 300;
    color: white;
    width: 200px;
    padding: 20px 40px;
    border: none;
    outline: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 7px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.btn_close {
    background-color: rgb(5, 107, 112);
    font-size: 20px;
    color: white;
    padding: 10px 15px 10px 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border: none;
}

.cancel_btn {
    font-size: 30px;
}

.cancel_btn:hover {
    transition: all 0.35s;
    color: #b2a365de;
}

.btn.btn-bg-slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #b2a365de;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 300ms ease-in-out;
}

.btn.btn-bg-slide:hover::before,
.btn.btn-bg-slide:active::before {
    transform: scaleX(1);
}

.btn.btn-bg-slide {
    z-index: 1;
}

.btn.btn-bg-slide:hover,
.btn.btn-bg-slide:focus {
    color: rgb(5, 107, 112);
    transition: all 0.4s;
}

/*--------------------------------------------------- HOVER EFFECT BTN ------------------------------------------------------*/

/* ---------------------------------------- Font Size Devices -----------------------------------------*/
@media screen and (max-width: 1500px) {
    .team_headline h1 {
        font-size: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .team_headline h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .team_headline h1 {
        font-size: 38px;
    }
}

@media screen and (max-width: 450px) {
    .btn.btn-bg-slide::before {
        animation: none;
        transition: none;
    }

    .btn.btn-bg-slide:hover::before,
    .btn.btn-bg-slide:active::before {
        animation: none;
        transition: none;
    }

    .btn.btn-bg-slide {
        animation: none;
        transition: none;
    }

    .btn.btn-bg-slide:hover,
    .btn.btn-bg-slide:focus {
        animation: none;
        transition: none;
        color: white;
    }

    .btn.btn-bg-slide::before {
        background: rgb(5, 107, 112);
    }
}

@media screen and (max-width: 400px) {
    .team_headline h1 {
        font-size: 30px;
    }
}

/* ---------------------------------------- Font Size Devices -----------------------------------------*/


@media screen and (max-width: 724px) {
    .doc_curric__content table tbody tr td {
        font-size: 18px;
    }

    .doc_curric__headline h2 {
        font-size: 25px;
    }

    .doc_curric__headline {
        margin: 35px auto 20px auto;
    }
}


@media screen and (max-width: 551px) {

    .team__container_doc {
        width: 90%;
    }

    .team_headline {
        width: 90%;
    }

    .team__container_doc_img {
        width: 300px;
        height: 400px;
    }

    .team__container_ZFA_img {
        width: 300px;
        height: 400px;
    }

    .doc_name_container h2 {
        font-size: 12px;
    }

    .doc__name p {
        font-size: 23px;
    }

    .team_headline {
        justify-content: center;
    }

    .button_container {
        position: absolute;
        left: 32%;
        top: 107%;
        color: white;
    }

    .btn {
        font-size: 15px;
        width: 110px;
        padding: 10px 16px;
        border-radius: 500px;
    }

    .cancel_btn {
        font-size: 20px;
    }

    .doc_curric {
        height: 750px;
    }

    .doc_curric__content table tbody tr td {
        font-size: 15px;
    }

    .doc_curric__headline h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {
    .team__container_doc_img {
        width: 100%;
        height: 400px;
    }

    .team__container_ZFA_img {
        width: 100%;
        height: 400px;
    }
}

@media screen and (max-width: 392px) {
    .doc_curric__content table tbody tr td {
        font-size: 14px;
    }

    .doc_curric__headline h2 {
        font-size: 16px;
    }

    .doc_curric {
        height: 760px;
    }
}

@media screen and (max-width: 352px) {
    .doc_curric {
        height: 850px;
    }
}

@media screen and (max-width: 350px) {
    .doc_name_container h2 {
        font-size: 10px;
    }

    .doc__name p {
        font-size: 20px;
    }

    .team__container_doc {
        gap: 7rem;
    }
}