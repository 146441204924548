.embla {
    max-width: 80%;
    margin: auto;
    --slide-height: 35rem;
    --slide-spacing: 8rem;
    /* space between images */
    --slide-size: 35%;
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.embla__slide__img {
    border-radius: 1.8rem;
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
}

.embla__controls {
    display: flex;
    grid-template-columns: auto 1fr;
    justify-content: center;
    margin-top: 1.8rem;
    /* space between arrow button and images */
}

.embla__buttons {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    /* space between circles */
    align-items: center;
}

.embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.1rem var(--arrow-button);
    width: 2.8rem;
    /* size of arrow*/
    height: 2.8rem;
    /* size of arrow*/
    z-index: 1;
    border-radius: 50%;
    color: var(--arrows);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.embla__button__svg {
    width: 35%;
    height: 35%;
}

.embla__parallax {
    border-radius: 1.8rem;
    height: 100%;
    overflow: hidden;
}

.embla__parallax__layer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.embla__parallax__img {
    max-width: none;
    flex: 0 0 calc(115% + (var(--slide-spacing) * 2));
    object-fit: cover;
}

@media screen and (max-width: 2000px) {

    .embla {
        --slide-spacing: 8rem;
        --slide-size: 47%;
    }

}

@media screen and (max-width: 1500px) {

    .embla {
        max-width: 80%;
        margin: auto;
        --slide-height: 35rem;
        --slide-spacing: 2rem;
        /* space between images */
        --slide-size: 65%;
    }

}

@media screen and (max-width: 650px) {

    .embla {
        max-width: 95%;
        margin: auto;
        --slide-height: 35rem;
        --slide-spacing: 0.5rem;
        /* space between images */
        --slide-size: 87%;
    }
    
}