h1 {
    font-family: var(--font-family2);
    font-weight: 300;
    color: #056b70ff;
    font-size: 40px;
}

.datenschutz_headline_h2 h2 {
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 18px;
}

.datenschutz_headline_h3 h3 {
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 16px;
}

.datenschutz_content {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 18px;
    font-weight: 300;
}

.datenschutz_content p {
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 18px;
    font-weight: 300;
}

.datenschutz {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 40px;
    margin: 270px auto 50px auto;
}

.datenschutz_width {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 90%;
}

.datenschutz_content p ul {
    list-style-position: inside;
}

@media screen and (max-width: 640px) {

    h1 {
        font-size: 25px;
    }

    .datenschutz_headline_h2 h2 {
        font-size: 15px;
    }

    .datenschutz_headline_h3 h3 {
        font-size: 15px;
    }

    .datenschutz_content p {
        font-size: 11px;
    }
}