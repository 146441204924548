.impressum{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    margin: 270px auto 50px auto;
}

.impressum_content {
    width: 90%;
}

.impressum_content p a{
    font-family: var(--font-family2);
    color: #056b70ff;
    font-size: 18px;
}

.impressum_content p {
        font-family: var(--font-family2);
        color: #056b70ff;
        font-size: 18px;
        display: flex;
        justify-content: flex-start;
}

.impressum_content h1 {
    font-family: var(--font-family2);
    font-weight: 300;
    color: #056b70ff;
    font-size: 40px;
    display: flex;
    justify-content: flex-start;
}

@media screen and (max-width: 640px) {

    .impressum_content p, .impressum_content p a{
        font-size: 12px;
    }

    .impressum_content p strong{
        font-size: 15px;
    }

    .impressum_content h1{
        font-size: 25px;
    }
}